type Logo = {};

export const Logo = (props: Logo) => (
    <div className="flex items-center">
        <svg
            width="250"
            height="50"
            viewBox="0 0 250 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0 0V37.5L5 40.6875L5.24691 40.4321C5.40123 40.1235 5.70987 39.5062 6.17284 38.4259L6.32716 38.1173L2.9321 35.9568V2.9321H36.1111V35.8025L19.4444 46.4506L11.1111 41.2037C10.6481 42.4383 10.3395 43.5185 10.1852 44.1358L19.4444 50L39.0432 37.5V0H0Z"
                fill="#4C4C4C"
            />
            <path
                d="M5.75 41.1742L2.1875 38.875L4.40294 31.75L9.0625 30.8125L5.75 41.1742Z"
                fill="url(#paint0_radial_542_11176)"
            />
            <path
                d="M25.9259 10.1853L25.6172 9.25934L25.4629 8.33342C25.6172 7.25317 26.2345 6.48157 27.3148 6.17292V5.70996H14.0432V6.17292C15.2777 6.48157 15.895 7.09885 15.895 8.33342C15.895 8.79638 15.7407 9.41366 15.5864 10.0309C15.5864 10.0309 5.70139 36.392 4.3125 40.25L7.71605 42.4383C8.79629 39.1976 12.9629 27.1606 12.9629 27.1606C14.5061 27.1606 15.895 27.3149 17.2839 27.6235C18.6728 27.9322 19.9074 28.5495 21.2963 29.1667C23.7654 30.5556 25.7716 32.2532 27.1605 34.2593C27.9321 35.3396 28.5493 36.4198 28.858 37.5001L34.2592 33.9507L25.9259 10.1853ZM13.7345 24.3828L19.2901 10.1853L25.9259 29.1667C22.5308 25.926 18.5185 24.3828 13.7345 24.3828Z"
                fill="#4C4C4C"
            />
            <path
                d="M54 38.0843H55.0437V47.0169H60.5728V47.9157H54V38.0843Z"
                fill="black"
            />
            <path
                d="M73.66 47.0169V47.9157H66.6782V38.0843H73.4484V38.9831H67.7219V42.4803H72.8278V43.3652H67.7219V47.0169H73.66Z"
                fill="black"
            />
            <path
                d="M87.3843 43H88.3858V46.75C87.925 47.1526 87.3796 47.4616 86.7496 47.677C86.1196 47.8923 85.4567 48 84.7609 48C83.7735 48 82.885 47.7846 82.0951 47.3539C81.3052 46.9232 80.6846 46.3287 80.2333 45.5702C79.7819 44.8024 79.5563 43.9457 79.5563 43C79.5563 42.0543 79.7819 41.2022 80.2333 40.4438C80.6846 39.676 81.3052 39.0768 82.0951 38.6461C82.885 38.2154 83.7782 38 84.775 38C85.5272 38 86.2183 38.1217 86.8484 38.3652C87.4784 38.5993 88.0096 38.9504 88.4422 39.4185L87.7934 40.0787C87.0129 39.3109 86.0209 38.927 84.8173 38.927C84.018 38.927 83.294 39.1049 82.6452 39.4607C82.0058 39.8071 81.5027 40.294 81.136 40.9213C80.7693 41.5393 80.5859 42.2322 80.5859 43C80.5859 43.7678 80.7693 44.4607 81.136 45.0787C81.5027 45.6966 82.0058 46.1835 82.6452 46.5393C83.2846 46.8951 84.0039 47.073 84.8032 47.073C85.8187 47.073 86.6791 46.8202 87.3843 46.3146V43Z"
                fill="black"
            />
            <path
                d="M101.904 45.2893H96.4034L95.2186 47.9157H94.1325L98.646 38.0843H99.6756L104.189 47.9157H103.089L101.904 45.2893ZM101.523 44.4466L99.1538 39.1657L96.7842 44.4466H101.523Z"
                fill="black"
            />
            <path
                d="M110.195 38.0843H111.239V47.0169H116.768V47.9157H110.195V38.0843Z"
                fill="black"
            />
            <path
                d="M137.679 47.9157L135.422 44.7556C135.168 44.7837 134.905 44.7978 134.632 44.7978H131.981V47.9157H130.937V38.0843H134.632C135.892 38.0843 136.88 38.3839 137.594 38.9831C138.309 39.5824 138.666 40.4064 138.666 41.4551C138.666 42.2228 138.469 42.8736 138.074 43.4073C137.688 43.9316 137.134 44.3109 136.409 44.5449L138.821 47.9157H137.679ZM134.604 43.9129C135.582 43.9129 136.33 43.6976 136.847 43.2669C137.364 42.8361 137.622 42.2322 137.622 41.4551C137.622 40.6592 137.364 40.0506 136.847 39.6292C136.33 39.1985 135.582 38.9831 134.604 38.9831H131.981V43.9129H134.604Z"
                fill="black"
            />
            <path
                d="M152.484 47.0169V47.9157H145.502V38.0843H152.272V38.9831H146.546V42.4803H151.652V43.3652H146.546V47.0169H152.484Z"
                fill="black"
            />
            <path
                d="M160.354 38.9831V42.8455H165.459V43.7444H160.354V47.9157H159.31V38.0843H166.08V38.9831H160.354Z"
                fill="black"
            />
            <path
                d="M179.59 47.0169V47.9157H172.608V38.0843H179.378V38.9831H173.652V42.4803H178.757V43.3652H173.652V47.0169H179.59Z"
                fill="black"
            />
            <path
                d="M193.157 47.9157L190.901 44.7556C190.647 44.7837 190.384 44.7978 190.111 44.7978H187.459V47.9157H186.415V38.0843H190.111C191.371 38.0843 192.358 38.3839 193.073 38.9831C193.787 39.5824 194.145 40.4064 194.145 41.4551C194.145 42.2228 193.947 42.8736 193.552 43.4073C193.167 43.9316 192.612 44.3109 191.888 44.5449L194.3 47.9157H193.157ZM190.083 43.9129C191.061 43.9129 191.808 43.6976 192.325 43.2669C192.842 42.8361 193.101 42.2322 193.101 41.4551C193.101 40.6592 192.842 40.0506 192.325 39.6292C191.808 39.1985 191.061 38.9831 190.083 38.9831H187.459V43.9129H190.083Z"
                fill="black"
            />
            <path
                d="M207.723 47.9157L205.466 44.7556C205.212 44.7837 204.949 44.7978 204.676 44.7978H202.024V47.9157H200.981V38.0843H204.676C205.936 38.0843 206.923 38.3839 207.638 38.9831C208.353 39.5824 208.71 40.4064 208.71 41.4551C208.71 42.2228 208.513 42.8736 208.118 43.4073C207.732 43.9316 207.177 44.3109 206.453 44.5449L208.865 47.9157H207.723ZM204.648 43.9129C205.626 43.9129 206.373 43.6976 206.891 43.2669C207.408 42.8361 207.666 42.2322 207.666 41.4551C207.666 40.6592 207.408 40.0506 206.891 39.6292C206.373 39.1985 205.626 38.9831 204.648 38.9831H202.024V43.9129H204.648Z"
                fill="black"
            />
            <path
                d="M221.738 45.2893H216.237L215.052 47.9157H213.966L218.48 38.0843H219.509L224.023 47.9157H222.923L221.738 45.2893ZM221.357 44.4466L218.987 39.1657L216.618 44.4466H221.357Z"
                fill="black"
            />
            <path
                d="M230.029 38.0843H231.072V47.0169H236.601V47.9157H230.029V38.0843Z"
                fill="black"
            />
            <path
                d="M245.319 48C244.585 48 243.88 47.883 243.203 47.6489C242.535 47.4148 242.018 47.1058 241.652 46.7219L242.061 45.9213C242.418 46.2772 242.893 46.5674 243.485 46.7921C244.077 47.0075 244.689 47.1152 245.319 47.1152C246.203 47.1152 246.866 46.956 247.307 46.6376C247.749 46.3099 247.97 45.8886 247.97 45.3736C247.97 44.9803 247.848 44.6667 247.604 44.4326C247.369 44.1985 247.077 44.0206 246.729 43.8989C246.381 43.7678 245.897 43.6273 245.276 43.4775C244.534 43.2903 243.941 43.1124 243.499 42.9438C243.057 42.7659 242.676 42.4991 242.357 42.1433C242.046 41.7875 241.891 41.3052 241.891 40.6966C241.891 40.2004 242.023 39.7509 242.286 39.3483C242.549 38.9363 242.954 38.6086 243.499 38.3652C244.045 38.1217 244.722 38 245.53 38C246.094 38 246.645 38.0796 247.181 38.2388C247.726 38.3886 248.196 38.5993 248.591 38.8708L248.238 39.6994C247.825 39.4279 247.383 39.2266 246.913 39.0955C246.442 38.9551 245.982 38.8848 245.53 38.8848C244.665 38.8848 244.012 39.0534 243.57 39.3904C243.137 39.7182 242.921 40.1442 242.921 40.6685C242.921 41.0618 243.038 41.3801 243.274 41.6236C243.518 41.8577 243.819 42.0403 244.176 42.1713C244.543 42.2931 245.032 42.4288 245.643 42.5787C246.367 42.7566 246.95 42.9345 247.392 43.1124C247.843 43.2809 248.224 43.5431 248.535 43.8989C248.845 44.2453 249 44.7182 249 45.3174C249 45.8137 248.864 46.2678 248.591 46.6798C248.328 47.0824 247.919 47.4054 247.364 47.6489C246.809 47.883 246.127 48 245.319 48Z"
                fill="black"
            />
            <path
                d="M64.125 16.5L61.375 24.25C61.125 24.875 61 25.625 60.875 26.625C60.875 27.375 61.125 28 61.5 28.375C61.875 28.75 62.5 29 63.375 29V29.5H54.25V29C55.125 28.875 55.875 28.5 56.375 27.875C57 27.25 57.5 26.125 58.125 24.5L66 3.875C66.125 3.375 66.25 3 66.25 2.625C66.25 1.75 65.75 1.25 64.875 1V0.625H74.625V1C73.75 1.25 73.375 1.75 73.25 2.625L73.375 3.25L73.5 4L81.25 26.375C81.375 27 81.75 27.5 82.25 28.125C82.75 28.75 83.25 29 83.875 29V29.5H73.875V29C75.375 28.625 76 27.625 76 26C76 24.625 75.5 23.25 74.5 21.75C73.5 20.25 72 19 70.125 18C69.125 17.5 68.25 17.125 67.25 16.875C66.25 16.625 65.25 16.5 64.125 16.5ZM64.75 14.375C68.25 14.375 71.25 15.5 73.75 17.875L68.75 3.875L64.75 14.375Z"
                fill="#BA9166"
            />
            <path
                d="M98.625 2.875C97.75 2.875 97 2.875 96.25 3C95.5 3.125 94.75 3.25 94.125 3.375C92.875 3.75 91.75 4.25 90.75 5.125C89.75 6 89 7.25 88.25 8.75L87.875 8.625L90 0C90.125 0.375 90.75 0.625 91.625 0.75L92.5 0.625H109.375C109.75 0.625 110 0.625 110.125 0.75C110.5 0.75 110.875 0.75 111.25 0.625C111.625 0.375 111.875 0.25 112.125 0L114.25 8.625L113.75 8.75C112.375 4.75 109 2.75 103.5 2.75V24.5C103.5 25.75 103.625 26.75 103.875 27.375C104.125 28 104.5 28.375 104.875 28.625C105 28.75 105.25 28.75 105.5 28.875C105.75 29 106 29 106.25 29V29.5H95.75V29C96.625 29 97.25 28.75 97.75 28.125C98.25 27.625 98.5 26.5 98.5 24.75V2.875H98.625Z"
                fill="#BA9166"
            />
            <path
                d="M137.625 1V0.625H148.25V1C147.25 1 146.5 1.375 146 2.125C145.5 2.875 145.25 4 145.25 5.375V24.25C145.25 25.875 145.5 27 146 27.875C146.5 28.75 147.25 29.125 148.25 29.125V29.625H137.625V29.125C138 29.125 138.375 29 138.75 28.875C139.125 28.75 139.375 28.625 139.625 28.375C140 27.875 140.25 27.375 140.375 26.75C140.5 26.125 140.5 25.25 140.5 24.25V10.75H127.375V24.25C127.375 25.875 127.625 27.125 128 27.875C128.5 28.625 129.125 29 130.125 29.125V29.625H119.625V29.125C121.625 29.125 122.5 27.75 122.5 25.125V4.875C122.5 3.625 122.25 2.75 121.625 2.125C121 1.5 120.375 1.125 119.5 1V0.625H130V1C128.875 1 128.125 1.5 127.75 2.375C127.375 3.25 127.25 4.25 127.25 5.375V8.75H140.375V6.25C140.375 4.75 140.25 3.625 140 2.875C139.75 2.125 139.5 1.625 139.125 1.375C138.875 1.25 138.25 1.125 137.625 1Z"
                fill="#BA9166"
            />
            <path
                d="M163.375 8.875C164.25 8.875 164.875 8.875 165.25 9C166.125 9 166.875 9 167.5 9C168.125 9 168.75 8.875 169.25 8.875C170 8.75 170.625 8.5 171 8.125C171.375 7.625 171.625 7 171.625 6.25L172 6.125L173.75 16L173.375 16.125C172.875 14.5 172.375 13.25 171.625 12.5C170.875 11.75 170 11.375 168.875 11.125C167.75 11 166 10.875 163.5 10.875V24.125C163.5 24.75 163.625 25.375 163.75 25.75C163.875 26.25 164.25 26.625 164.875 26.875C165.5 27.25 166.375 27.375 167.625 27.375C168.125 27.375 168.375 27.375 168.625 27.25C169.875 27.25 171.125 27.125 172.25 26.75C173.375 26.375 174.5 25.875 175.75 25.25C177.875 24 179.75 21.875 181.25 18.875L181.625 19L178 30C177.5 29.5 177 29.25 176.25 29.25L175.25 29.375H173.875H155.625V28.875C157.5 28.875 158.5 27.25 158.5 24V5.875C158.5 4.25 158.375 3.125 158 2.375C157.625 1.625 156.875 1.125 155.75 1V0.625H174.75C175 0.625 175.125 0.625 175.25 0.75C176 0.625 176.625 0.5 177 0.125L179 8.875L178.625 9C178.25 8 177.625 7 176.875 6.25C176.5 5.875 176.125 5.5 175.625 5.125C175.125 4.75 174.75 4.5 174.125 4.25C173.125 3.75 172 3.375 170.875 3C170.25 2.875 169.75 2.75 169.125 2.75C168.5 2.75 167.875 2.625 167.25 2.625C166 2.625 165.125 2.75 164.625 3.125C164.125 3.5 163.75 3.75 163.625 4.125C163.5 4.5 163.375 5.125 163.375 5.875V8.875Z"
                fill="#BA9166"
            />
            <path
                d="M209.25 11.625V5.625C209.25 3.875 209 2.75 208.5 2.125C208 1.5 207.25 1.125 206.25 1V0.625H214.875V1C214.625 1 214.375 1.125 214 1.25C213.75 1.375 213.5 1.375 213.375 1.5C213 1.625 212.75 2.125 212.5 2.75C212.25 3.375 212.125 4.25 212.125 5.375V25C212.125 27.625 213 29 214.875 29.125V29.625H206.25V29.125C208.25 29 209.25 27.625 209.25 25V20.625C209.25 19.75 209.25 19.125 209.125 18.625C209 18.125 208.875 17.75 208.625 17.5C208.375 17.125 208.125 16.875 207.875 16.625L206.625 15.625L192.875 4.25V25C192.875 26.375 193.125 27.375 193.625 28.125C194.125 28.875 194.875 29.25 195.75 29.25V29.75H187.25V29.25C188.25 29.125 189 28.625 189.5 28C190 27.375 190.125 26.375 190.125 25.125V5.625C190.125 4.25 189.875 3.25 189.5 2.5C189.125 1.75 188.375 1.25 187.25 1V0.625H198.875V1C198.25 1 197.875 1.125 197.75 1.5C197.875 1.875 198.25 2.375 199.125 3L209.25 11.625Z"
                fill="#BA9166"
            />
            <path
                d="M229.625 16.5L226.875 24.25C226.625 24.875 226.5 25.625 226.375 26.625C226.375 27.375 226.625 28 227 28.375C227.375 28.75 228 29 228.875 29V29.5H219.875V29C220.75 28.875 221.5 28.5 222 27.875C222.625 27.25 223.125 26.125 223.75 24.5L231.625 3.875C231.75 3.375 231.875 3 231.875 2.625C231.875 1.75 231.375 1.25 230.5 1V0.625H240.25V1C239.375 1.25 239 1.75 238.875 2.625L239 3.25L239.125 4L246.875 26.375C247 27 247.375 27.5 247.875 28.125C248.375 28.75 248.875 29 249.5 29V29.5H239.5V29C241 28.625 241.625 27.625 241.625 26C241.625 24.625 241.125 23.25 240.125 21.75C239.125 20.25 237.625 19 235.75 18C234.75 17.5 233.875 17.125 232.875 16.875C231.875 16.625 230.75 16.5 229.625 16.5ZM230.25 14.375C233.75 14.375 236.75 15.5 239.25 17.875L234.375 3.875L230.25 14.375Z"
                fill="#BA9166"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_542_11176"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(6.9375 41.875) rotate(-87.1014) scale(9.88765 3.90906)"
                >
                    <stop />
                    <stop offset="1" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    </div>
);
