import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { startLoading, endLoading } from 'src/store/slices/loadingSlice';
import { AuthContext } from 'src/contexts/authContext';
import {
    Checkbox,
    EmailInput,
    InputLabel,
    PasswordInput,
} from 'src/components/common/form/input';
import PrimaryButton from 'src/components/common/buttons/PrimaryButton';
import Link from 'next/link';
import { useForm } from 'react-hook-form';
import {
    authValidationRules,
    referralValidationRules,
} from 'src/libs/validation';
import { FORGOT_PASSWORD_PAGE_URL } from 'src/constants/links';
import useServerErrorHandler from 'src/hooks/useServerErrorHandler';

type TProps = { [x: string]: any };

const LoginByEmail: React.FunctionComponent<{}> = () => {
    const dispatch = useDispatch();
    const { logInWithEmail } = useContext(AuthContext);
    const [handleServerError] = useServerErrorHandler();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isValid },
    } = useForm({
        mode: 'all',
    });

    const onSubmit = async ({ email, password }: TProps) => {
        dispatch(startLoading());
        logInWithEmail(email, password)
            .then((data) => {
                // setError('');
            })
            .catch((err) => handleServerError(err))
            .finally(() => dispatch(endLoading()));
    };

    watch();

    return (
        <div className=" mt-8">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mb-[1.5rem]">
                    <InputLabel htmlFor="email">Email</InputLabel>

                    <div className="mt-2">
                        <EmailInput
                            className="kyc-text-field"
                            placeholder="Enter"
                            registerReactHookInput={register(
                                'email',
                                referralValidationRules.email,
                            )}
                            error={errors.email}
                        />
                    </div>
                </div>

                <div className="mb-[1.5rem]">
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <div className="mt-1">
                        <PasswordInput
                            autoComplete="on"
                            className="kyc-text-field w-full"
                            placeholder="Password"
                            registerReactHookInput={register(
                                'password',
                                authValidationRules.password,
                            )}
                            error={errors.password}
                        />
                    </div>
                </div>

                <div className="flex flex-col items-start xl:flex-row mb-[1.5rem]">
                    <div className="flex items-center">
                        <Checkbox
                            registerReactHookInput={register(
                                'remember-me',
                                // authValidationRules.password,
                            )}
                        />
                        <InputLabel
                            className="ml-[0.5rem] font-mont text-sm text-black mt-[0.05rem] whitespace-nowrap"
                            htmlFor="remember-me"
                        >
                            Remember me
                        </InputLabel>
                    </div>

                    <div className="text-sm 2xl:ml-44 mt-4 xl:mt-0 xl:ml-auto">
                        <Link href={FORGOT_PASSWORD_PAGE_URL}>
                            <a className="font-mont text-blue font-bold hover:text-indigo-500 whitespace-nowrap">
                                Forgot your password?
                            </a>
                        </Link>
                    </div>
                </div>

                <div>
                    <PrimaryButton
                        type="submit"
                        paddingX="px-[4rem]"
                        disabled={!isValid}
                        label="Login"
                    />
                </div>
                <div className="mb-[4.15rem]"></div>
            </form>
        </div>
    );
};

export default LoginByEmail;
