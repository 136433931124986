import { classNames } from '../../../libs/utils';

type TabObj = { label: string; disabled?: boolean };

type TabsProps = {
    tabs: TabObj[];
    onClick: (tab: TabObj) => any;
    value: string;
};

export const MainTabs = (props: TabsProps) => (
    <div className="border-b border-gray-200 w-full">
        <nav className="-mb-px flex" aria-label="Tabs">
            {props.tabs.map((tab) => (
                <a
                    key={tab.label}
                    href="#"
                    onClick={() => !tab.disabled && props.onClick(tab)}
                    className={classNames(
                        props.value === tab.label
                            ? 'border-black text-blue font-bold font-mont'
                            : 'border-transparent text-gray-500 hover:text-blue hover:border-gray-300',
                        'whitespace-nowrap pt-4 pb-2 px-3 border-b font-mont text-sm font-mont',
                    )}
                    aria-current={props.value ? 'page' : undefined}
                >
                    {tab.label}
                </a>
            ))}
        </nav>
    </div>
);
