import clsx from 'clsx';
import Link from 'next/link';
import {
    PRIVACY_POLICY_PAGE_URL,
    TERMS_AND_CONDITIONS_PAGE_URL,
} from 'src/constants/links';
import { Logo } from '../common/logo';

const AuthLayout = ({ children, shouldJustifyCenter }: any) => {
    return (
        <div
            className="flex w-full justify-start md:justify-center items-center md:min-h-full h-screen bg-white md:bg-[#f9fafe]"
            style={{
                alignItems: window.innerHeight < 825 ? 'start' : 'center',
            }}
        >
            <div className="w-full md:w-auto md:max-w-[992px] lg::max-w-[992px] 2xl:min-w-[1380px]">
                <div className="flex md:w-auto md:border-[1px] md:rounded-xl border-white md:border-inherit md:h-[770px] 2xl:h-[880px]">
                    <div className="leftSide flex flex-col w-full md:w-1/2 2xl:w-full 2xl:max-w-[718px] px-[35px] py-[60px] md:px-[3rem] md:pt-[3rem] 2xl:pt-[5rem] md:rounded-l-xl 2xl:px-[7.5rem] 2xl:pt-[7.5rem] bg-white relative">
                        <div
                            className={clsx(
                                'flex mb-6 xl:mb-16 md:justify-start',
                                shouldJustifyCenter
                                    ? 'justify-center'
                                    : 'justify-start',
                            )}
                        >
                            <Logo />
                        </div>
                        <div>{children}</div>
                        <div className="absolute bottom-10">
                            <Link href={TERMS_AND_CONDITIONS_PAGE_URL}>
                                <a className="mr-5 whitespace-nowrap cursor-pointer text-sm font-mont text-gray-300">
                                    Terms and conditions
                                </a>
                            </Link>
                            <Link href={PRIVACY_POLICY_PAGE_URL}>
                                <a className="whitespace-nowrap cursor-pointer text-sm font-mont text-gray-300">
                                    Privacy policy
                                </a>
                            </Link>
                        </div>
                    </div>
                    <div className="rightSide grow hidden md:flex md:w-1/2 md:rounded-r-xl md:max-w-[100%] lg:max-w-[660px]">
                        <img
                            className="h-full w-full object-cover inset-0 md:rounded-r-xl"
                            src="/images/AuthFormImage.png"
                            alt="logo"
                            width="660"
                            height="840"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthLayout;
