import React, { useState } from 'react';
import Link from 'next/link';
import { MainTitle } from 'src/components/common/titles/MainH1';
import { MainTabs } from 'src/components/common/tabs';
import LoginByEmail from 'src/components/auth/login/LogInByEmail';
import LoginByPhone from 'src/components/auth/login/LoginByPhone';
import AuthLayout from 'src/components/auth/AuthLayout';
import { SIGNUP_PAGE_URL } from 'src/constants/links';

const EMAIL_TAB = 'By Email';
const PHONE_TAB = 'By Phone';

const LogIn: React.FunctionComponent<{}> = () => {
    const [error, setError] = useState('');

    const tabs = ['By Email', 'By Phone'];
    const [active, setActive] = useState(tabs[0]);
    return (
        <AuthLayout>
            <div className="w-auto sm:w-full ">
                <div>
                    <MainTitle>Login</MainTitle>
                    <div className="font-mont mt-2 text-sm text-black">
                        New to the platform?&nbsp;&nbsp;
                        <div className="inline-block font-mont text-sm text-blue font-bold hover:text-blue">
                            <Link href={SIGNUP_PAGE_URL}>Register</Link>
                        </div>
                    </div>
                </div>

                <div className="w-full">
                    <MainTabs
                        tabs={[{ label: EMAIL_TAB }, { label: PHONE_TAB }]}
                        value={active}
                        onClick={(tab) => {
                            setActive(tab.label);
                            setError('');
                        }}
                    />
                </div>
            </div>
            {active === EMAIL_TAB ? <LoginByEmail /> : <LoginByPhone />}
        </AuthLayout>
    );
};

export default LogIn;
