import React from 'react';
import Countdown from 'react-countdown';

type TProps = {
    countDown: number;
    twoDigits?: boolean;
    setCountDown: (val: number) => void;
    title?: string;
};

const addFirstZero = (seconds: number, twoDigits?: boolean) => {
    let normalized: number | string = seconds;
    if (twoDigits) {
        normalized = seconds < 10 ? '0' + seconds : seconds;
    }

    return normalized;
};

const CustomCountdown = ({
    countDown,
    setCountDown,
    title,
    twoDigits,
}: TProps) => {
    return !!countDown ? (
        <Countdown
            date={Date.now() + countDown}
            onComplete={() => setCountDown(0)}
            renderer={({ seconds }) =>
                seconds
                    ? `${title || ''}${addFirstZero(seconds, twoDigits)} sec`
                    : ''
            }
        />
    ) : null;
};

export default React.memo(CustomCountdown);
